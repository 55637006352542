import getters from "@/store/modules/recruiter/getters";
import mutations from "@/store/modules/recruiter/mutations";
import actions from "@/store/modules/recruiter/actions";
import { RecruiterState } from "@/store/modules/recruiter/interfaces";
import { CandidateStatusOption } from "@/interfaces/recruiter/admin/all_candidates";
export default {
  namespaced: true,
  state: (): RecruiterState => ({
    loading: false,
    post_job_limit_error: "",
    jobs_posted_loading: false,
    poke_candidate_loading: false,
    add_standardised_job: null,
    edited_Standardised_job: false,
    view_candidates_dynamically: {
      active: false,
      loading: false,
      job_id: null,
      job_title: "",
      skill: "",
      user_ids: []
    },
    // Using in job listing page
    jobs_posted: {
      pagination: 1,
      total: 0,
      results: [],
      date_filter: [],
      searched_job_title: null
    },
    // Using to display jobs in dashboard
    top_jobs_posted: {
      total: 0,
      results: []
    },
    recommend_job_profiles: [],
    // When recruiter want to view matching candidate this payload will contain job_id & cv_id
    // Job id and cv id is required to view candidate profile
    candidate_view_payload: null,
    all_candidates: {
      total: 0,
      results: [],
      filtered_count: -1
    },
    interviews: {
      search_job_loading: false,
      searched_job: null,
      pagination: 1,
      interview_listing: new Map(),
      selected_candidate: []
    },
    view_candidates_pagination: {
      pagination: 1,
      searched_skill_filter: ""
    },
    assessment_listing: {
      fetch_loading: false,
      selected_dates: [],
      pagination: 1,
      data: {
        total: 0,
        results: []
      }
    },
    knowledge_base: {
      data: [],
      info: {
        flag: false,
        msg: ""
      },
      page: 1,
      limit: 8,
      total: 0
    },
    searched_text: "",
    candidate_filter: CandidateStatusOption.SHORTLISTED,
    selected_job: null,
    view_talent_pool_pagination: {
      pagination: 1,
      searched_skill_filter: ""
    },
    all_talent_pool_candidates: {
      total: 0,
      results: [],
      filtered_count: -1
    },
    view_talent_pool_candidates_dynamically: {
      active: false,
      loading: false,
      job_id: null,
      job_title: "",
      skill: "",
      user_ids: []
    }
  }),
  getters: getters,
  mutations: mutations,
  actions: actions
};
