/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  GOOGLE_AUTH,
  LINKEDIN_AUTH,
  LOGIN_USER,
  LOGOUT_USER,
  AUTH_LOADING,
  SET_COMPANY,
  SET_USER,
  SET_USER_PROPERTY,
  SIGNUP_USER,
  UPDATE_USER_DETAILS,
  USER_DETAILS_BY_ID,
  USER_PROFILE,
  USER_PROFILE_BY_ID,
  USER_STATE,
  VERIFY_EMAIL,
  REHYDRATE_STATE,
  NAVIGATE_USER_BASED_ON_ROLE,
  GET_USER_DETAILS,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  UPDATE_USER_PROFILE,
  UPDATE_USER_COMPANY,
  UPLOAD_HIGHLIGHT_AWARDS,
  REMOVE_HIGHLIGHT_AWARDS,
  VERIFY_EMAIL_ANAUTHORIZED
} from "@/store/modules/auth/constants";
import { Commit, Dispatch } from "vuex";
import {
  AuthState,
  EmailVerfiedPayload,
  ForgotPasswordPayload,
  InitUserResponse,
  LoginActionPayload,
  RemoveAwardPayload,
  ResetPasswordPayload,
  SetUserPropertyPayload,
  SignupActionPayload,
  UpdateCompanyPayload,
  UserState
} from "@/store/modules/auth/interfaces";
import {
  forgot_password,
  get_user,
  get_user_profile,
  google_auth,
  linkedin_auth,
  login_user,
  logout_user,
  remove_highlight_awards,
  reset_password,
  signup_user,
  update_user_company,
  update_user_details,
  update_user_profile,
  verify_email
} from "@/store/apis/auth";
import router from "@/router";
import { User } from "@/interfaces/data_objects/user";
import {
  GET_ROLE_BY_NAME,
  INIT_COMMON_DATA,
  RESET_COMMON_STATE,
  ROLES
} from "@/store/modules/common/constants";
import {
  RESET_ROOT_STATE,
  ROOT_ERROR,
  ROOT_NOTIFICATION
} from "@/store/modules/root/constants";
import { Candidates } from "@/interfaces/data_objects/candidates";
import {
  INIT_RECRUITER,
  RESET_RECRUITER_STATE
} from "@/store/modules/recruiter/constants";
import {
  INIT_CANDIDATE,
  RESET_CANDIDATE_STATE,
  SET_MATCHING_JOBS
} from "@/store/modules/candidates/constants";
import {
  RESET_CANDIDATE_DATA,
  RESET_RECRUITER_DATA
} from "@/store/modules/onboarding/constants";
import { upload_highlight_awards } from "@/store/apis/auth";
import { RESET_RECRUITER_ADMIN_STATE } from "@/store/modules/recruiter_admin/constants";
import { RESET_MY_NEXT_MOVE_STATE } from "../my_next_move/constants";
import i18n from "@/i18n";
export default {
  /**
   * Login user function
   * @param commit
   * @param payload: LoginActionPayload
   */
  [LOGIN_USER]: async (
    { commit }: { commit: Commit },
    payload: LoginActionPayload
  ): Promise<boolean> => {
    try {
      commit(AUTH_LOADING, true);
      // Login API call
      const response = await login_user(payload);
      const user: User = response.data.data.user;
      const token = response.data.data.token;
      const refresh_token = user.refresh_token;
      localStorage.setItem("token", token);
      if (refresh_token) localStorage.setItem("refresh_token", refresh_token);
      // Set user & user token in store
      commit(SET_USER, user);
      commit(ROOT_NOTIFICATION, null, { root: true });
      commit(ROOT_ERROR, null, { root: true });
      return true;
    } catch (e) {
      commit(AUTH_LOADING, false);
      commit(ROOT_ERROR, e, { root: true });
      return false;
    }
  },
  /**
   * Register new user
   * @param commit
   * @param payload: SignupActionPayload
   * @return boolean
   */
  [SIGNUP_USER]: async (
    { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
    payload: SignupActionPayload
  ): Promise<boolean> => {
    try {
      commit(AUTH_LOADING, true);
      const response = await signup_user(payload);
      // Set user & user token in store
      commit(SET_USER, response.data.user);
      const refresh_token = response.data.user.refresh_token;
      localStorage.setItem("token", response.data.token);
      if (refresh_token) localStorage.setItem("refresh_token", refresh_token);
      commit(USER_STATE, UserState.EMAIL_VERIFICATION_PENDING);
      await dispatch(`common/${ROLES}`, {}, { root: true });
      commit(ROOT_NOTIFICATION, "Signup Successful", { root: true });
      commit(AUTH_LOADING, false);
      return true;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      commit(AUTH_LOADING, false);
      commit(ROOT_ERROR, e.response.data.message, { root: true });
      return false;
    }
  },
  /**
   * Verify email function
   * @param commit
   * @param payload
   */
  [VERIFY_EMAIL]: async (
    { commit }: { commit: Commit },
    payload: EmailVerfiedPayload
  ): Promise<boolean> => {
    try {
      commit(AUTH_LOADING, true);
      const response = await verify_email(payload);
      commit(USER_STATE, UserState.EMAIL_VERIFICATION_COMPLETED);
      commit(ROOT_NOTIFICATION, response.data.message, { root: true });
      const obj: SetUserPropertyPayload = {
        obj_name: "is_email_confirmed",
        value: true
      };
      commit(SET_USER_PROPERTY, obj);
      commit(AUTH_LOADING, false);
      return true;
    } catch (e) {
      commit(AUTH_LOADING, false);
      commit(ROOT_ERROR, e, { root: true });
      return false;
    }
  },
  [GOOGLE_AUTH]: async (): Promise<void> => {
    await google_auth();
  },
  [LINKEDIN_AUTH]: async (): Promise<void> => {
    await linkedin_auth();
  },
  // Fetching user profile => return upload response column from uploads table
  [USER_PROFILE]: async ({ commit }: { commit: Commit }): Promise<void> => {
    try {
      commit(AUTH_LOADING, true);
      // Fetching user profile
      const response = await get_user_profile();
      if (
        response.data.data.status === "in_progress" &&
        router.currentRoute.name !== "profile-fetching"
      ) {
        commit(USER_STATE, UserState.CANDIDATE_PROFILE_PENDING);
        await router.push("/candidate/profile-loading");
      } else if (response.data.data.status === "completed") {
        const profile_details: Candidates.CandidateDetails =
          response.data.data.upload_response;
        commit(USER_PROFILE, profile_details);
      }
      commit(AUTH_LOADING, false);
    } catch (e) {
      commit(AUTH_LOADING, false);
      commit(USER_PROFILE);
      commit(ROOT_ERROR, e, { root: true });
    }
  },
  [USER_PROFILE_BY_ID]: async (
    { commit }: { commit: Commit },
    id: number
  ): Promise<Candidates.CandidateDetails | null> => {
    try {
      const response = await get_user_profile(id);
      return response.data.data.upload_response;
    } catch (e) {
      commit(ROOT_ERROR, e, { root: true });
      return null;
    }
  },
  [USER_DETAILS_BY_ID]: async (
    { commit }: { commit: Commit },
    id: number
  ): Promise<User | null> => {
    try {
      const response = await get_user(id);
      return response.data.data;
    } catch (e) {
      commit(ROOT_ERROR, e, { root: true });
      return null;
    }
  },
  /**
   * Update user details => name, email, password, new password
   */
  [UPDATE_USER_DETAILS]: async (
    { commit }: { commit: Commit },
    payload: FormData
  ): Promise<boolean> => {
    try {
      commit(AUTH_LOADING, true);
      const response = await update_user_details(payload); // API call
      const user = response.data.data;
      commit(SET_USER, user);
      commit(`candidate/${SET_MATCHING_JOBS}`, [], { root: true });
      commit(AUTH_LOADING, false);
      return true;
    } catch (e) {
      commit(AUTH_LOADING, false);
      commit(ROOT_ERROR, e, { root: true });
      return false;
    }
  },
  [LOGOUT_USER]: async ({
    commit,
    state
  }: {
    commit: Commit;
    state: AuthState;
  }): Promise<void> => {
    if (state.user) {
      const user_id = state.user.id;
      try {
        await logout_user(user_id);
      } catch (e) {
        //
      }
    }
    commit(LOGOUT_USER);
    const locale = localStorage.getItem("locale") || "en";
    localStorage.clear();
    sessionStorage.clear();
    localStorage.setItem("locale", locale);
    commit(`candidate/${RESET_CANDIDATE_STATE}`, null, { root: true });
    commit(`recruiter/${RESET_RECRUITER_STATE}`, null, { root: true });
    commit(`common/${RESET_COMMON_STATE}`, null, { root: true });
    commit(`onboarding/${RESET_RECRUITER_DATA}`, null, { root: true });
    commit(`onboarding/${RESET_CANDIDATE_DATA}`, null, { root: true });
    commit(`recruiter_admin/${RESET_RECRUITER_ADMIN_STATE}`, null, {
      root: true
    });
    commit(`my_next_move/${RESET_MY_NEXT_MOVE_STATE}`, null, { root: true });
    commit(`${RESET_ROOT_STATE}`, null, { root: true });
    commit(ROOT_NOTIFICATION, null, { root: true });
    commit(ROOT_ERROR, null, { root: true });
    // await router.push("/");
  },
  /**
   * Initialize user data
   */
  [REHYDRATE_STATE]: async ({
    commit,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getters,
    rootGetters,
    dispatch
  }: {
    commit: Commit;
    getters: any;
    rootGetters: any;
    dispatch: Dispatch;
  }): Promise<boolean> => {
    try {
      // Get user with company details
      const response = await get_user();
      const data: InitUserResponse = response.data.data;
      const company = data.companyDetail;
      delete data.companyDetail;
      const user: User = data;
      commit(SET_USER, user);
      commit(SET_COMPANY, company);
      const refresh_token = user.refresh_token;
      if (refresh_token) localStorage.setItem("refresh_token", refresh_token);
      await dispatch(`common/${INIT_COMMON_DATA}`, {}, { root: true });
      const recruiter_role =
        rootGetters[`common/${GET_ROLE_BY_NAME}`]("recruiter");
      const candidate_role =
        rootGetters[`common/${GET_ROLE_BY_NAME}`]("individual");
      const recruiter_admin =
        rootGetters[`common/${GET_ROLE_BY_NAME}`]("recruiter admin");
      if (!user.is_email_confirmed)
        commit(USER_STATE, UserState.EMAIL_VERIFICATION_PENDING);
      else if (!user.is_onboarding_completed)
        commit(USER_STATE, UserState.ONBOARDING_PENDING);
      else if (!user.is_account_confirmed)
        commit(USER_STATE, UserState.ONBOARDING_COMPLETED);
      else if (user.role_id === recruiter_role.id) {
        commit(USER_STATE, UserState.RECRUITER);
        await dispatch(`recruiter/${INIT_RECRUITER}`, {}, { root: true });
      } else if (user.role_id === recruiter_admin.id) {
        commit(USER_STATE, UserState.RECRUITER_ADMIN);
        await dispatch(`recruiter/${INIT_RECRUITER}`, {}, { root: true });
      } else if (user.role_id === candidate_role.id) {
        commit(USER_STATE, UserState.CANDIDATE);
        await dispatch(`candidate/${INIT_CANDIDATE}`, {}, { root: true });
      }
      return true;
    } catch (e) {
      await dispatch(LOGOUT_USER, {});
      return false;
    }
  },
  /**
   * Navigate user based on role or state
   * User state already set at this point and common data initialized successfully
   * @param getters
   * @param rootGetters
   */
  [NAVIGATE_USER_BASED_ON_ROLE]: async ({
    getters,
    rootGetters
  }: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getters: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    rootGetters: any;
  }): Promise<void> => {
    const user = getters[GET_USER_DETAILS];
    const recruiter_role =
      rootGetters[`common/${GET_ROLE_BY_NAME}`]("recruiter");
    const candidate_role =
      rootGetters[`common/${GET_ROLE_BY_NAME}`]("individual");
    const recruiter_admin =
      rootGetters[`common/${GET_ROLE_BY_NAME}`]("recruiter admin");
    if (!user.is_email_confirmed) await router.push("/auth/verify-email");
    else if (!user.is_onboarding_completed) await router.push("/onboarding");
    else if (!user.is_account_confirmed) await router.push("/approval-pending");
    else if (
      user.role_id === recruiter_role.id ||
      user.role_id === recruiter_admin.id
    )
      await router.push("/recruiter");
    else if (user.role_id === candidate_role.id)
      await router.push("/candidate");
  },
  [FORGOT_PASSWORD]: async (
    { commit }: { commit: Commit },
    payload: ForgotPasswordPayload
  ): Promise<boolean> => {
    try {
      commit(AUTH_LOADING, true);
      const response = await forgot_password(payload);
      commit(ROOT_NOTIFICATION, response.data.message, { root: true });
      commit(AUTH_LOADING, false);
      return true;
    } catch (e) {
      commit(ROOT_ERROR, e, { root: true });
      commit(AUTH_LOADING, false);
      return false;
    }
  },
  [RESET_PASSWORD]: async (
    { commit }: { commit: Commit },
    payload: ResetPasswordPayload
  ): Promise<boolean> => {
    try {
      commit(AUTH_LOADING, true);
      const response = await reset_password(payload);
      commit(ROOT_NOTIFICATION, response.data.message, { root: true });
      commit(AUTH_LOADING, false);
      return true;
    } catch (e) {
      commit(ROOT_ERROR, e, { root: true });
      commit(AUTH_LOADING, false);
      return false;
    }
  },
  /**
   * Function to update user profile
   */
  [UPDATE_USER_PROFILE]: async (
    { commit }: { commit: Commit },
    payload: Candidates.CandidateDetails
  ): Promise<boolean> => {
    try {
      commit(AUTH_LOADING, true);
      const response = await update_user_profile(payload);
      commit(`candidate/${SET_MATCHING_JOBS}`, [], { root: true });
      commit(USER_PROFILE, response.data.data.upload_response);
      commit(AUTH_LOADING, false);
      return true;
    } catch (e) {
      commit(AUTH_LOADING, false);
      return false;
    }
  },
  /**
   * FUnction to update user company
   */
  [UPDATE_USER_COMPANY]: async (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    { commit }: { commit: Commit },
    payload: UpdateCompanyPayload
  ): Promise<boolean> => {
    try {
      //
      await update_user_company(payload);
      return true;
    } catch (e) {
      return false;
    }
  },
  /**
   * For uploading highlight awards
   */
  [UPLOAD_HIGHLIGHT_AWARDS]: async (
    { commit }: { commit: Commit },
    payload: FormData
  ): Promise<boolean> => {
    try {
      commit(AUTH_LOADING, true);
      const response = await upload_highlight_awards(payload);
      commit(USER_PROFILE, response.data.data.upload_response);
      commit(AUTH_LOADING, false);
      return true;
    } catch (e) {
      commit(AUTH_LOADING, false);
      commit(ROOT_ERROR, e, { root: true });
      return false;
    }
  },
  /**
   * For removing HIGHLIGHT_AWARDS
   */
  [REMOVE_HIGHLIGHT_AWARDS]: async (
    { commit }: { commit: Commit },
    payload: RemoveAwardPayload
  ): Promise<boolean> => {
    try {
      commit(AUTH_LOADING, true);
      // call backend api
      const response = await remove_highlight_awards(payload);
      commit(ROOT_NOTIFICATION, response.data.message, { root: true });
      commit(REMOVE_HIGHLIGHT_AWARDS, payload.id); // Remove from store
      commit(AUTH_LOADING, false);
      return true;
    } catch (e) {
      commit(ROOT_ERROR, e, { root: true });
      commit(AUTH_LOADING, false);
      return false;
    }
  },
  [VERIFY_EMAIL_ANAUTHORIZED]: async (
    { commit }: { commit: Commit },
    payload: EmailVerfiedPayload
  ): Promise<boolean> => {
    try {
      commit(AUTH_LOADING, true);
      const response = await verify_email(payload);
      commit(USER_STATE, UserState.UNAUTHENTICATED);
      commit(ROOT_NOTIFICATION, response.data.message, { root: true });
      commit(AUTH_LOADING, false);
      return true;
    } catch (e) {
      commit(AUTH_LOADING, false);
      commit(ROOT_ERROR, e, { root: true });
      return false;
    }
  }
};
