import { RouteConfig } from "vue-router";
import { UserState } from "@/store/modules/auth/interfaces";

export const talent_pool_routes: RouteConfig = {
  path: "talent-pool",
  component: () => import("@/views/recruiter/RecruiterTalentPool.vue"),
  meta: {
    user_state: [UserState.RECRUITER, UserState.RECRUITER_ADMIN]
  },
  children: [
    {
      path: "",
      name: "recruiter-talent-pool",
      component: () =>
        import("@/views/recruiter/candidates/ViewTalentPool.vue"),
      meta: {
        name: "nav-sidebar.talent-pool",
        icon: require("@/assets/icons/nav_sidebar/active/teacher.svg"),
        user_state: [UserState.RECRUITER, UserState.RECRUITER_ADMIN]
      }
    },
    {
      path: "profile",
      name: "candidate-profile-talent",
      component: () =>
        import("@/views/recruiter/candidates/ViewCandidateProfile.vue"),
      meta: {
        name: "nav-sidebar.talent-pool",
        icon: require("@/assets/icons/nav_sidebar/active/teacher.svg"),
        user_state: [UserState.RECRUITER, UserState.RECRUITER_ADMIN]
      }
    }
  ]
};
