export enum ResumeUploadsStatus {
  IN_PROGRESS = "in_progress",
  COMPLETED = "completed",
  FAILED = "failed"
}

export enum Portals {
  HCMS = "HCMS",
  COREHCS = "COREHCS",
  COREHCMS = "COREHCMS"
}
